.container {
  margin: 1.1rem auto;
}

.step {
  margin-bottom: 1.1rem;
}

.title {
  @apply text-eucalyptus;
  font-size: 1.2rem;
  font-weight: bold;
}

.subtitle {
  @apply text-gray-800;
  font-size: 1rem;
  margin-bottom: 1rem;
  opacity: 0.6;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
}

.gridTwoColumns {
  column-gap: 1.1rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 0.5rem;
}

.gridThreeColumns {
  column-gap: 1.1rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 0.5rem;
}

@media (min-width: 768px) {
  .gridTwoColumns {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .gridThreeColumns {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
